



import Component from 'vue-class-component';
import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import { authSessionsModule, blocklistRecordsModule } from '@/store/index';

const SessionTabs = [
  { id: 'authSessions', i18n: 'sessions' },
  { id: 'blocklistRecords', i18n: 'blocklist_records' }
];

@Component({
  name: 'auth-sessions-tabs'
})
export default class AuthSessionsTabs extends Vue {
  itemId: string | null = null;

  get items() {
    return SessionTabs;
  }

  mounted() {
    this.itemId = this.getItemIdByRouteName();
  }

  getItemIdByRouteName() {
    const { name } = this.$route;
    let result;
    if (name === 'authSessions') {
      result = this.items[0].id;
    } else {
      result = this.items[1].id;
    }
    return result;
  }

  @Watch('itemId')
  itemChangeHandler(v: string, p: string) {
    let route = null;
    if (v === p) return;

    if (v === 'authSessions') {
      route = authSessionsModule.getListRoute();
    } else {
      route = blocklistRecordsModule.getListRoute();
    }

    if (p && route) this.$router.replace(route);
  }
}
